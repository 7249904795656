/* 1. @font-face 규칙 추가 */
@font-face {
  font-family: "Pretendard";
  src: url("./assets/fonts/Pretendard-Regular.woff2") format("woff2"), url("./assets/fonts/Pretendard-Regular.woff") format("woff"), url("./assets/fonts/Pretendard-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("./assets/fonts/Pretendard-Bold.woff2") format("woff2"), url("./assets/fonts/Pretendard-Bold.woff") format("woff"), url("./assets/fonts/Pretendard-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* 2. body 스타일에 Pretendard 적용 */
body {
  margin: 0;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  height: 100%;
  margin: 0;
  background-color: #ffffff;
}
